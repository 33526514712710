body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.logo {
  height: 55px;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}
.logo img {
  height: 50px;
  display: block;
  background: #f36055;
  margin: auto;
  padding: 10px 25px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 4px;
}
.logo-bar {
  height: 125px;
  -webkit-flex-grow: 1;
          flex-grow: 1;
}
.logo-bar img {
  width: 90%;
  max-width: 300px;
  display: block;
  background: #f36055;
  margin: auto;
  padding: 10px 25px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 4px;
}
.loading {
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999999999;
}
.loading-circle {
  margin: auto;
  height: 125px;
  width: 125px;
  background-color: rgba(255, 255, 255, 0.75);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  border-radius: 50%;
  box-shadow: 0 1px 4px rgba(0, 0, 0.25);
}
.loading-circle img {
  width: 45px;
  margin: auto;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.loader {
  border: 6px solid #a4a5a7;
  border-radius: 50%;
  border-left: 6px solid #be1e2d;
  width: 80px;
  height: 80px;
  -webkit-animation: spin 1.25s linear infinite;
  animation: spin 1.25s linear infinite;
  margin: auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
/*# sourceMappingURL=App.css.map */
